
import { defineComponent, onMounted, onBeforeUnmount, ref } from 'vue';
import axios from 'axios'
// import WangEditor from 'wangeditor';
import { useStore } from 'vuex'
import { getEditableContent, saveEditedContent } from '@/API/program'
import { message } from "ant-design-vue"
export default defineComponent({
    name: 'Wording Edit',
    setup() {
        const store = useStore()
        let instance: any;
        const init = () => {  
            const E = window.wangEditor
            instance = new window.wangEditor('#toolbar-container', '#text-container')
            const params = {
                'programId': store.state.pgmData.programId,
                // 'programId': '0b53c2c3-d445-421d-b237-7d915095d385',
                'type': 'External',
                'lang': 'en'
            }
            if (sessionStorage.getItem('params') && sessionStorage.getItem('params') !== null) {
                Object.assign(params, JSON.parse(sessionStorage.getItem('params') as string))
            }
            getEditableContent({params: params}).then(res => {
                

                instance.txt.html(`<div><div id="preview-box-com">${String(res.editable).replaceAll('<script', '&lt;script')}</div></div>`)
            })
            // instance.config.menus = [
            //     'head',  // 标题
            //     'bold',  // 粗体
            //     'fontSize',  // 字号
            //     'fontName',  // 字体
            //     'italic',  // 斜体
            //     'underline',  // 下划线
            //     'strikeThrough',  // 删除线
            //     'foreColor',  // 文字颜色
            //     'backColor',  // 背景颜色
            //     'link',  // 插入链接
            //     'list',  // 列表
            //     'justify',  // 对齐方式
            //     'quote',  // 引用
            //     'emoticon',  // 表情
            //     'image',  // 插入图片
            //     'table',  // 表格
            //     'video',  // 插入视频
            //     'code',  // 插入代码
            //     'undo',  // 撤销
            //     'redo'  // 重复
            // ]
            // 隐藏表情，和视频菜单
            const { BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E
            // 创建 class
            class CustomColorMenu extends BtnMenu {
                constructor(editor: any) {
                    const $elem = E.$(
                        `<div id="color-button" class="w-e-menu" style='width:80px'>
                            <button style='width:80px'>替换颜色</button>
                        </div>`
                        
                    )
                    super($elem, editor)
                }
                // 菜单点击事件
                clickHandler() {
                    const editor = this.editor
                    const isEmptySelection = editor.selection.isSelectionEmpty()
                    const $selectionElem = editor.selection.getSelectionContainerElem()?.elems[0]

                    if ($selectionElem == null) return

                    // 获取选区范围的文字
                    const $selectionText = editor.selection.getSelectionText()
                    // // 如果设置的是 a 标签就特殊处理一下，避免回车换行设置颜色无效的情况
                    // // 只处理选中a标签内全部文字的情况，因为选中部分文字不存在换行颜色失效的情况
                    // if ($selectionElem.nodeName === 'A' && $selectionElem.textContent === $selectionText) {
                    //     // 创建一个相当于占位的元素
                    //     const _payloadElem = $('<span>&#8203;</span>').getNode()
                    //     // 添加到a标签之后
                    //     $selectionElem.appendChild(_payloadElem)
                    // }
                    debugger
                    if(document && (document?.getElementById('color') as HTMLInputElement).value) {
                        editor.cmd.do('foreColor', (document?.getElementById('color') as HTMLInputElement).value)
                    }
                    

                    if (isEmptySelection) {
                        // 需要将选区范围折叠起来
                        editor.selection.collapseRange()
                        editor.selection.restoreSelection()
                    }
                }
                // 菜单激活状态
                tryChangeActive() {
                    this.active() // 菜单激活
                    // this.unActive() // 菜单不激活
                }
            }
            // 注册菜单 [全局模式注册菜单]
            E.registerMenu('CustomColorMenu', CustomColorMenu)


            instance.config.excludeMenus = [
                'emoticon',
                'video'
            ]
            // 可使用 base64 格式保存图片。即，可选择本地图片，编辑器用 base64 格式显示图片。
            // 上传图服务器
            // instance.config.uploadImgServer = '/upload-img'
            // uploadImgShowBase64（base64 格式）和 uploadImgServer（上传图片到服务器）两者不能同时使用
            instance.config.uploadImgShowBase64 = true
            // 隐藏网络图片
            instance.config.showLinkImg = false
            instance.config.Hr = false
            instance.create()
            const colorSelector = document.createElement('INPUT')
            colorSelector.setAttribute('id', 'color')
            colorSelector.setAttribute('placeholder', '请输入颜色英文')
            const toolBarElem = document?.querySelector('.w-e-toolbar')?.append(colorSelector)
        }
        // 在路由卸载之后，需要清掉编辑器
        onBeforeUnmount(() => {
            instance.destroy()
            instance = null
        })
        // 进入到对比wording页面
        const handleCompareWording = () => {
            window.open(`${window.location.origin}/#/wordingcompare`)
        }
        // 保存编辑后的wording
        const handleSaveWording = () => {
            const params = {
                "editable": instance.txt.html(),
                "programId": store.state.pgmData.programId
            }
            if (sessionStorage.getItem('params') && sessionStorage.getItem('params') !== null) {
                Object.assign(params, JSON.parse(sessionStorage.getItem('params') as string))
            }
            
            saveEditedContent(params).then(() => {
                message.success('Save successed!')
            })
        }

        const isFixed = ref(false)
        onMounted(() => {
            init()
            window.addEventListener('scroll', () => {
                isFixed.value = document.documentElement.scrollTop > 100
            })
        })
        return {
            isFixed,
            handleCompareWording,
            handleSaveWording
        }
    }
})
